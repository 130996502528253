import React, { Component } from "react";
import Helmet from "react-helmet";
import AudioPlayer from "react-h5-audio-player";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import pratap from "../../blogposts/images/profile-small.jpg";
import { formatDate } from "../utils/global";
import PostTags from "../components/PostTags";
import Layout from "../layout";
import SEO from "../components/SEO";
import ViewCounter from "../components/ViewCounter";
import CommentBox from "../components/Comment";

export default class PageTemplate extends Component {
  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.markdownRemark;
    const post = postNode.frontmatter;

    if (!post.id) {
      post.id = slug;
    }
    let thumbnail;
    if (post.thumbnail) {
      thumbnail = post.thumbnail.childImageSharp.fixed;
    }

    const date = formatDate(post.date);
    return (
      <Layout>
        <Helmet>
          <title>{`${post.title} – Podcast`}</title>
        </Helmet>
        <SEO postPath={slug} postNode={postNode} postSEO />
        <div className="single container">
          <article>
            <header
              className={`single-header ${!thumbnail ? "no-thumbnail" : ""}`}
            >
              {thumbnail && (
                <Img fixed={post.thumbnail.childImageSharp.fixed} />
              )}
              <div className="flex">
                <h1>{post.title}</h1>
                <div className="post-meta">
                  <Link to="/me">
                    <img src={pratap} className="avatar-small" alt="Pratap" />
                  </Link>
                  <time className="date">{date}</time>
                  <span>
                    <ViewCounter id={slug} isPodcast />
                  </span>
                </div>
                <PostTags tags={post.tags} />
              </div>
            </header>
            {post.podcastUrl && (
              <AudioPlayer
                controls
                src={post.podcastUrl}
                customAdditionalControls={[]}
              />
            )}
            <br />

            <div
              className="page"
              dangerouslySetInnerHTML={{ __html: postNode.html }}
            />
            <CommentBox />
          </article>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query PodcastBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        template
        podcastUrl
        thumbnail {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        tags
        slug
        date
      }
      fields {
        slug
        date
      }
    }
  }
`;
